import React from 'react';
import { Box, Typography, Container, CssBaseline } from '@mui/material';
import { useStaticQuery, graphql } from 'gatsby';
import { styled } from '@mui/material/styles';

import ThemeProviderWrapper from '../theme/themeProvider';
import Logo from '../components/general/logoSign';
import StaticPageFooter from '../layout/staticPageFooter';
  
const MainContent = styled(Box)(
    () => `
        height: 100%;
        display: flex;
        flex: 1;
        overflow: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `
);
  
function StatusMaintenance() {
  
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: {eq: "maintenance.svg"}) {
                publicURL
            }
        }
    `);

    return (
      <ThemeProviderWrapper>
        <CssBaseline />
        <title>Status - Maintenance</title>
        <MainContent sx={{ mt: 5 }}>
          <Container maxWidth="md">
            <Logo />
            <Box textAlign="center" sx={{ mb: 4 }}>
              <Container maxWidth="md">
                <Typography variant="h2" sx={{ mt: 4, mb: 2 }}>
                  Sorry we're currently performing some maintenance on the site.
                </Typography>
                <Typography
                  variant="h3"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{ mb: 4 }}
                >
                  Hopefully we should be back up soon, we apologize for any inconvenience caused.
                </Typography>
              </Container>
              <img alt="Maintenance" height={250} src={data.file.publicURL}
              />
            </Box>
            <StaticPageFooter />
          </Container>
        </MainContent>
      </ThemeProviderWrapper>
    );
  }
  
  export default StatusMaintenance;
  